<template>
  <div class="vh-100 bg-dark">
  
    <div class="text-white p-5 mx-3">
      <header class="jumbotron">
        <h3>
          <strong>{{ currentUser.nome }}</strong>
        </h3>
      </header>
      <div class="text-start mx-5 py-5">
        <p>
          <strong>Token:</strong>
          {{ currentUser.access_token.substring(0, 20) }} ...
          {{
            currentUser.access_token.substr(
              currentUser.access_token.length - 20
            )
          }}
        </p>
        <p>
          <strong>Id:</strong>
          {{ currentUser.id }}
        </p>
        <p>
          <strong>Nome:</strong>
          {{ currentUser.nome }}
        </p>
        <p>
          <strong>Chave:</strong>
          {{ currentUser.chave }}
        </p>
        <p>
          <strong>Matrícula:</strong>
          {{ currentUser.matricula }}
        </p>
        <p>
          <strong>Email:</strong>
          {{ currentUser.email }}
        </p>
        <p>
          <strong>Grupo:</strong>
          {{ currentUser.grupo }}
        </p>
        <p>
          <strong>Nível:</strong>
          {{ currentUser.nivel }}
        </p>
      </div>
    </div>
 
</div>
</template>

<script>
export default {
  name: "Profile",
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
};
</script>
